<template>
  <div>
    <c-logo />
    <c-request-steps class="mb-8" />
      <c-message
        v-if="!published"
        type="error"
        class="errors mb-8"
      >
        <p class="mb-3">
          Das Formular kann aktuell nicht ausgefüllt und abgeschickt werden.
          <template v-if="isPausedByDate">
            <span> Dies </span>
            <span v-if="publishAt > today">ist </span>
            <span v-else-if="unpublishAt < today">war </span>
            im Zeitraum {{ readablePublishAt }} Uhr bis {{ readableUnpublishAt }} Uhr möglich.
          </template>
          Diese Ansicht dient zur Orientierung, welche Informationen benötigt
          <span v-if="unpublishAt !== null && unpublishAt < today">wurden.</span>
          <span v-else>werden.</span>
        </p>
      </c-message>
    <router-view />
  </div>
</template>

<script>

import CMessage from '../components/CMessage.vue'
import CRequestSteps from '../components/CRequestSteps.vue'
import CLogo from '../components/CLogo.vue'

export default {
  name: 'VRequest',

  components: {
    CMessage,
    CRequestSteps,
    CLogo
  },

  computed: {
    today: () => new Date(),
    hasErrors () {
      return !!Object.keys(this.$store.state.errors).length
    },
    errorDetails () {
      return this.$store.state.errors.detail
    },
    published () {
      return this.$store.state.publishedInfo.published
    },
    publishAt () {
      return this.$store.state.publishedInfo.publishAt
    },
    unpublishAt () {
      return this.$store.state.publishedInfo.unpublishAt
    },
    readablePublishAt () {
      return this.$store.state.publishedInfo.readablePublishAt
    },
    readableUnpublishAt () {
      return this.$store.state.publishedInfo.readableUnpublishAt
    },
    isPausedByDate () {
      return this.$store.state.publishedInfo.isPausedByDate
    }
  }
}
</script>
